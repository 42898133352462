





<nav class="navbar navbar-expand-md navbar-light bg-white fixed-top">
  
  <a class="navbar-brand"><img height="60" width="150" src="assets/images/jurist.jpg"></a>
  
  
  <button type="button" class="navbar-toggler bg-light" data-toggle="collapse" data-target="#nav">
    <span class="navbar-toggler-icon"></span>
  </button>
    
  <div class="collapse navbar-collapse justify-content-between" id="nav">
    
    <ul class="navbar-nav">
      
      <li class="nav-item" >
        
        
        <a class="nav-link text-light px-3 text-dark" [class.nav-active]="currentUrl === '/'" (click)="navigateToUrl('/')" routerLink="/">Home</a>
      
      </li>
      <li class="nav-item" >
        <a class="nav-link text-light px-3 text-dark" [class.nav-active]="currentUrl === '/special-feature'" (click)="navigateToUrl('/special-feature')" routerLink="/special-feature">Special Features</a>
      </li>
      
      <li class="nav-item dropdown" data-toggle="dropdown">
      
        <a class="nav-link text-dark px-3 dropdown-toggle" [class.nav-active]="currentUrl === '/team'" href="#">Team</a>
        <div class="dropdown-menu">
          <a class="dropdown-item text-dark" (click)="navigateToUrl('/team')">Main Partners</a>
          <a class="dropdown-item text-dark" (click)="navigateToUrl('/team')">Associate Partners</a>
          
          
        </div>
      </li>
      
      <li class="nav-item">
        <a class="nav-link text-dark px-3" [class.nav-active]="currentUrl === '/area-of-practice'"  (click)="navigateToUrl('/area-of-practice')" routerLink="/area-of-practice">Area Of Practice</a>
      </li>
      
      <li class="nav-item">
        <a class="nav-link text-dark px-3" [class.nav-active]="currentUrl === '/proficiency'" (click)="navigateToUrl('/proficiency')" routerLink="/proficiency">Proficiency</a>
      </li>

      <li class="nav-item">
        <a class="nav-link text-dark px-3" [class.nav-active]="currentUrl === '/clients'" (click)="navigateToUrl('/clients')" routerLink="clients">Clients</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-dark px-3" [class.nav-active]="currentUrl === '/job-careers'" (click)="navigateToUrl('/job-careers')" >Jobs / Carrers</a>
      </li>
      
      <li class="nav-item">
        <a class="nav-link text-dark px-3" [class.nav-active]="currentUrl === '/contact-us'" (click)="navigateToUrl('/contact-us')"z routerLink="contact-us">Contact us</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-dark px-3" [class.nav-active]="currentUrl === '/alumini'" (click)="navigateToUrl('/alumini')"z routerLink="contact-us">Alumni</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-dark px-3" [class.nav-active]="currentUrl === '/testimonial'" (click)="navigateToUrl('/testimonial')"z routerLink="contact-us">Testimonial</a>
      </li>
      
    </ul>
    
  </div>
  
</nav>
