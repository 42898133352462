import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent, FooterComponent, PracticeAreasComponent } from './component';
import { BannerComponent } from './component/banner/banner.component';



@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    PracticeAreasComponent,
    BannerComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    PracticeAreasComponent,
    BannerComponent
  ]
})
export class SharedComponentModule { }
