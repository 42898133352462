import { Component } from '@angular/core';
import { Router,NavigationEnd  } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'jurist-czar';
  currentUrl = '';
  constructor(private route:Router){

    this.routeEvent(this.route);
  
  }
  routeEvent(router: Router){
    router.events.subscribe(e => {
      if(e instanceof NavigationEnd){
        this.currentUrl = e.url;
      }
    });
  }
}
