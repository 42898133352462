import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  currentUrl = '';
  constructor(
    private router: Router
  ) {
    this.routeEvent(this.router);
  }

  ngOnInit(): void {
  }


  navigateToUrl(url: string) {
    this.router.navigate([url]);
  }


  routeEvent(router: Router){
    router.events.subscribe(e => {
      if(e instanceof NavigationEnd){
        this.currentUrl = e.url;
      }
    });
  }

}
