


<app-header></app-header>
<div class="container app-home" >
  <div class="row">
    <div class="col-md-12">
      <marquee class="marq" style="margin-top: 5px;" direction = "left" loop="" > 
        <div class="geek1 change-color"><h6 class="">Previously Known as <b>www.sekherkumarandassociates.com</b></h6></div> 
      </marquee>
    </div>
  </div>
    
</div>
<router-outlet></router-outlet>
<app-footer></app-footer>

