<!-- Footer -->
<footer class="page-footer bg-dark font-small mdb-color">

    <!-- Footer Links -->
    <div class="container-fluid text-center text-md-left">
  
      <!-- Footer links -->
      <div class="row text-center text-md-left mt-3">
  
        <!-- Grid column -->
        <div class="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
          
            <a class="navbar-brand" routerLink="/"><img class="foot-img" height="100" width="250" src="assets/images/jurist.jpg"></a>
        </div>
        <!-- Grid column -->
  
        <hr class="w-100 clearfix d-md-none">
  
        <!-- Grid column -->
        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
          <h6 class="text-uppercase mb-4 font-weight-bold">About Us</h6>
          <p>
            <a (click)="navigateToUrl('/special-feature')">Special Features</a>
          </p>
          <p>
            <a (click)="navigateToUrl('/contact-us')">Contact Us</a>
          </p>
          <p>
            <a (click)="navigateToUrl('/team')">Team</a>
          </p>
          <p>
            <a (click)="navigateToUrl('/alumini')">Alumni</a>
          </p>
        </div>
        <!-- Grid column -->
  
        <hr class="w-100 clearfix d-md-none">
  
        <!-- Grid column -->
        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
          <h6 class="text-uppercase mb-4 font-weight-bold">Useful links</h6>
          <p>
            <a (click)="navigateToUrl('/')">Home</a>
          </p>
          
          <p>
            <a (click)="navigateToUrl('/area-of-practice')">Area Of Practice</a>
          </p>
          <p>
            <a (click)="navigateToUrl('/testimonial')">Testimonial</a>
          </p>
          <p>
            <a (click)="navigateToUrl('/faq')">FAQ</a>
          </p>
          
        </div>
  
        <!-- Grid column -->
        <hr class="w-100 clearfix d-md-none">
  
        <!-- Grid column -->
        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
          <h6 class="text-uppercase mb-4 font-weight-bold">Contact</h6>
          <p>
            <i class="fa fa-home mr-3"></i>District Court Saket, NEW DELHI</p>
          <p>
            <i class="fa fa-envelope mr-3"></i><a href="mailto:contactus@juristczar.com">contactus@juristczar.com</a></p>
          <p>
            <i class="fa fa-phone mr-3"></i> +91 9873461699 / 9312661699</p>
          
        </div>
        <!-- Grid column -->
  
      </div>
      <!-- Footer links -->
  
      <hr>
  
      <!-- Grid row -->
      <div class="row d-flex align-items-center">
  
        <!-- Grid column -->
        <div class="col-md-7 col-lg-8">
  
          <!--Copyright-->
          <p class="text-center text-md-left">Designed By: 
            <a target="_blank" href="https://www.jmvl.co.uk">
              <strong> JMVL Ltd</strong>
            </a>
          </p>
  
        </div>
        <!-- Grid column -->
  
        <!-- Grid column -->
        <div class="col-md-5 col-lg-4 ml-lg-0">
  
          <!-- Social buttons -->
          <div class="text-center text-md-right">
            <ul class="list-unstyled list-inline">
              <li class="list-inline-item">
                <a href="https://www.facebook.com/Sekher-Kumar-Associates-292095070818345" target="_blank" class="btn-floating btn-sm rgba-white-slight mx-1">
                  <i class="fa fa-facebook-f"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a class="btn-floating btn-sm rgba-white-slight mx-1">
                  <i class="fa fa-twitter"></i>
                </a>
              </li>
              
              <li class="list-inline-item">
                <a href="https://www.linkedin.com/company/jurist-czar/" target="_blank" class="btn-floating btn-sm rgba-white-slight mx-1">
                  <i class="fa fa-linkedin"></i>
                </a>
              </li>
            </ul>
          </div>
  
        </div>
        <!-- Grid column -->
  
      </div>
      <!-- Grid row -->
  
    </div>
    <!-- Footer Links -->
  
  </footer>
  <!-- Footer -->