










<div class="app-practice-area">

  <div class="practice-area">
    <a (click)="navigateToUrl('/area-of-practice')">Corporate Litigation</a>
  </div>
  <div class="practice-area">
    <a (click)="navigateToUrl('/area-of-practice')">Employer / Employee Disputes</a>
  </div>
  <div class="practice-area">
    <a (click)="navigateToUrl('/area-of-practice')">Cyber Laws</a>
  </div>
  <div class="practice-area">
    <a (click)="navigateToUrl('/area-of-practice')">Civil Litigation</a>
  </div>
  <div class="practice-area">
    <a (click)="navigateToUrl('/area-of-practice')">Criminal Litigation</a>
  </div>
  <div class="practice-area">
    <a (click)="navigateToUrl('/area-of-practice')">Matrimonial Disputes</a>
  </div>
  <div class="practice-area">
    <a (click)="navigateToUrl('/area-of-practice')">Fomation of Companies, Societies and Trusts</a>
  </div>
  <div class="practice-area">
    <a (click)="navigateToUrl('/area-of-practice')">Medical Jurisprudence and Litigation</a>
  </div>
  <div class="practice-area">
    <a (click)="navigateToUrl('/area-of-practice')">Execution Petition (Under Order XXI, CPC)
      (Execution of Arbitral Awards/ Execution of Decree)</a>
  </div>
  <div class="practice-area">
    <a (click)="navigateToUrl('/area-of-practice')">Consumer cases</a>
  </div>
  <div class="practice-area">
    <a (click)="navigateToUrl('/area-of-practice')">Banking & Finance</a>
  </div>
  <div class="practice-area">
    <a (click)="navigateToUrl('/area-of-practice')">Arbitration Matters</a>
  </div>
  <div class="practice-area">
    <a (click)="navigateToUrl('/area-of-practice')">Real Estate</a>
  </div>
  <div class="practice-area">
    <a (click)="navigateToUrl('/area-of-practice')">Taxation</a>
  </div>
  <div class="practice-area">
    <a (click)="navigateToUrl('/area-of-practice')">Political parties</a>
  </div>
  <div class="practice-area">
    <a (click)="navigateToUrl('/area-of-practice')">Recovery Suits</a>
  </div>
  <div class="practice-area">
    <a (click)="navigateToUrl('/area-of-practice')">Compilance</a>
  </div>
  <div class="practice-area">
    <a (click)="navigateToUrl('/area-of-practice')">Intellectual Property</a>
  </div>
</div>  